<template>
  <v-card>
    <v-row class="pl-3">
      <v-col cols="3">
        <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormattedMomentjs"
              append-icon="mdi-calendar"
              @click:append="on.click"
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable locale="th-th" @input="menu = false"> </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormattedMomentjs2"
              append-icon="mdi-calendar"
              @click:append="on.click"
              readonly
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:append-outer>
                <v-btn small color="primary" @click="handleSelectDate">เรียกดู</v-btn>
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="date2" no-title scrollable locale="th-th" @input="menu2 = false"> </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="px-3">
      <!-- total_all -->
      <v-col cols="12">
        <v-card class="bg-gradient" dark>
          <v-card-title>
            <v-icon size="40" color="#fff">mdi-currency-usd </v-icon>
            <div class="pl-2" style="color: #aaff6d">ยอดชำระทั้งหมด</div>
          </v-card-title>
          <v-card-text>
            <div class="font-weight-bold text-2xl text-center text--white">฿ {{ formatInt(sumTotal) }}</div>
          </v-card-text>
        </v-card>
      </v-col>
      <!--total_pay_all -->
      <v-col md="3" sm="6" cols="12">
        <v-card outlined elevation="1">
          <v-card-title>
            <div>เงินสด</div>
            <v-spacer></v-spacer>
            <v-avatar size="50" color="#47B881" class="elevation-1">
              <v-icon dark color="white" size="30"> mdi-cash-multiple </v-icon>
            </v-avatar>
            <v-card-text>
              <div class="font-weight-bold text-2xl">฿ {{ formatInt(cash) }}</div>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-col>
      <!-- total_remain -->
      <v-col md="3" sm="6" cols="12">
        <v-card outlined elevation="1">
          <v-card-title>
            <div>โมบายแบงค์กิ้ง</div>
            <v-spacer></v-spacer>
            <v-avatar size="50" color="#FDBC15" class="elevation-1">
              <v-icon dark color="white" size="30"> mdi-cellphone-check </v-icon>
            </v-avatar>
            <v-card-text>
              <div class="font-weight-bold text-2xl">฿ {{ formatInt(mobile) }}</div>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-col>
      <!-- total_cancel -->
      <v-col md="3" sm="6" cols="12">
        <v-card outlined elevation="1">
          <v-card-title>
            <div>บัตรเครดิต</div>
            <v-spacer></v-spacer>
            <v-avatar size="50" color="primary" class="elevation-1">
              <v-icon dark color="white" size="30"> mdi-credit-card </v-icon>
            </v-avatar>
            <v-card-text>
              <div class="font-weight-bold text-2xl">฿ {{ formatInt(credit) }}</div>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col md="3" sm="6" cols="12">
        <v-card outlined elevation="1">
          <v-card-title>
            <div>อื่นๆ</div>
            <v-spacer></v-spacer>
            <v-avatar size="50" color="#9C0E05" class="elevation-1">
              <v-icon dark color="white" size="30"> mdi-chart-line-variant </v-icon>
            </v-avatar>
            <v-card-text>
              <div class="font-weight-bold text-2xl">฿ {{ formatInt(other) }}</div>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <div class="font-weight-bold pl-3 pt-5">รายการชำระวันนี้</div>
    <v-row class="pa-3">
      <v-col>
        <vue-excel-xlsx :data="filteredItems" :columns="columnsXcel" :filename="filename" :sheetname="'Sheet1'">
          <v-btn small color="success" class="text-capitalize">
            <v-icon small class="mr-2"> mdi-microsoft-excel</v-icon> Excel
          </v-btn>
        </vue-excel-xlsx>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-select v-model="selected" :items="list_selected" item-value="value" item-text="label" dense solo> </v-select>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
      disable-sort
    >
      <template v-slot:no-data>
        <span class="text-center">ยังไม่มีข้อมูล</span>
      </template>

      <template v-slot:[`item.payment_date`]="{ item }">
        <v-row class="flex-nowrap items-center align-center pl-2">
          <div>
            {{ formatDate(item.payment_date) }}
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.receipt_code`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div class="font-weight-bold text-truncate pl-2" style="max-width: 180px">
            {{ item.receipt_code }}
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.fname`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div class="text-truncate pl-2" style="max-width: 180px">{{ item.fname }} {{ item.lname }}</div>
        </v-row>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div class="pl-2">
            {{ item.phone }}
          </div>
        </v-row>
      </template>

      <template v-slot:[`item.total_all`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div class="pl-2 font-weight-bold text--black">฿ {{ formatInt(item.total_all) }}</div>
        </v-row>
      </template>
      <template v-slot:[`item.total_pay`]="{ item }">
        <v-row class="flex-nowrap items-center align-center">
          <div class="pl-2 font-weight-bold text--black">฿ {{ formatInt(item.total_pay) }}</div>
        </v-row>
      </template>
      <template v-slot:[`item.payment_method`]="{ item }">
        <v-row class="flex-nowrap items-center align-center pl-2">
          <div v-if="item.payment_method == 1" class="success--text">
            <v-chip class="ma-2" color="#47B881" label text-color="white" small> เงินสด </v-chip>
          </div>
          <div v-if="item.payment_method == 2" class="warning--text">
            <v-chip class="ma-2" color="#FEC400" label text-color="white" small> โมบายแบงค์กิ้ง </v-chip>
          </div>
          <div v-if="item.payment_method == 3" class="warning--text">
            <v-chip class="ma-2" color="primary" label text-color="white" small> บัตรเครดิต </v-chip>
          </div>
          <div v-if="item.payment_method == 4" class="warning--text">
            <v-chip class="ma-2" color="#9C0E05" label text-color="white" small> อื่นๆ </v-chip>

            <v-tooltip v-model="show" top>
              <template v-slot:activator>
                <v-btn icon @click="show = !show">
                  <v-icon color="#9C0E05"> mdi-help-circle </v-icon>
                </v-btn>
              </template>
              <span> {{ item.method_text }}</span>
            </v-tooltip>
          </div>
        </v-row>
      </template>
    </v-data-table>
    <div class="d-flex flex-row-reverse pt-2">
      <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
    </div>
  </v-card>
</template>

<script>
import FormatNumber from '@/helper/function'
import moment from 'moment'
export default {
  data() {
    return {
      showEye: false,
      menu: false,
      menu2: false,
      payReport: [],
      today_total: '',
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: 'วันที่', align: 'left', value: 'payment_date' },
        { text: 'รหัสคำสั่งซื้อ', align: 'left', value: 'receipt_code' },
        { text: 'ลูกค้า', align: 'left', value: 'fname' },
        { text: 'เบอร์โทร', align: 'left', value: 'phone' },
        { text: 'ชำระ', align: 'left', value: 'total_pay' },
        { text: 'ช่องทางชำระ', align: 'left', value: 'payment_method' },
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),

      columnsXcel: [
        {
          label: 'รหัสคำสั่งซื้อ',
          field: 'receipt_code',
        },
        {
          label: 'ลูกค้า',
          field: 'fname',
        },
        {
          label: 'เบอร์โทร',
          field: 'phone',
        },
        {
          label: 'วันที่',
          field: 'payment_date',
        },
        {
          label: 'ชำระ',
          field: 'total_pay',
        },
        {
          label: 'ช่องทางชำระ',
          field: 'payment_method',
          dataFormat: this.userStatus,
        },
      ],
      id_cancel: '',
      note_text: '',
      dialogCancel: false,
      password: '',
      resultCheckPass: '',
      selected: '',
      list_selected: [
        { value: '', label: 'ทั้งหมด' },
        { value: '1', label: 'เงินสด' },
        { value: '2', label: 'โมบายแบงค์กิ้ง' },
      ],
      cash: 0,
      mobile: 0,
      credit: 0,
      other: 0,
      sumTotal: 0,
      show: false,
    }
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? this.formatDate(this.date) : ''
    },
    computedDateFormattedMomentjs2() {
      return this.date2 ? this.formatDate(this.date2) : ''
    },
    filename() {
      return 'รายการเงินเข้า' + this.formatDate(this.date) + '-' + this.formatDate(this.date2)
    },
    filteredItems() {
      return this.payReport.filter(i => {
        return !this.selected || i.payment_method == this.selected
      })
    },
  },

  mounted() {
    this.getReportPayDate(this.date + '/' + this.date2)
  },
  methods: {
    formatInt(num) {
      return FormatNumber.formatInt(num)
    },
    formatFloat(num) {
      return FormatNumber.formatFloat(num)
    },
    formatDate(date) {
      moment.locale('th')
      return moment(date).add(543, 'year').format('DD MMMM YYYY')
    },
    handleSelectDate() {
      this.getReportPayDate(this.date + '/' + this.date2)
    },
    getReportPayDate(date) {
      this.$store.dispatch('getReportPayDate', date).then(res => {
        console.log(res)
        var getCash = 0
        var getMobile = 0
        var getCredit = 0
        var getOther = 0

        for (var i = 0; i < res.getTotal.length; i++) {
          console.log(res.getTotal[i].payment_method == 1)
          if (res.getTotal[i].payment_method == 1) {
            console.log('cash')
            getCash = res.getTotal[i].total_pay_all
          }
          if (res.getTotal[i].payment_method == 2) {
            console.log('mobile')
            getMobile = res.getTotal[i].total_pay_all
          }
          if (res.getTotal[i].payment_method == 3) {
            getCredit = res.getTotal[i].total_pay_all
          }
          if (res.getTotal[i].payment_method == 4) {
            getOther = res.getTotal[i].total_pay_all
          }
        }

        this.cash = getCash
        this.mobile = getMobile
        this.credit = getCredit
        this.other = getOther
        this.sumTotal = getCash + getMobile + getCredit + getOther

        this.payReport = res.listReport
      })
    },
    showCancel(id) {
      this.id_cancel = id
      this.dialogCancel = true
    },
    handleDetail(id) {
      this.$router.push('/list-order-daily/billing/' + id)
    },

    userStatus(value) {
      if (value == 1) {
        return 'เงินสด'
      } else if (value == 2) {
        return 'โมบายแบงค์กิ้ง'
      } else if (value == 3) {
        return 'บัตรเครดิต'
      } else {
        return 'อื่นๆ'
      }
    },
    handleEdit(id) {
      this.$store.dispatch('getReceiptOrderById', id).then(order => {
        console.log(order)
        const order_list = []
        order.order_list.map(res => {
          console.log(res)
          if (res.remain != 0 && res.order_type != 'product') {
            Object.assign(res, { price_pay: parseInt(res.remain) })
            order_list.push(res)
          }
        })
        const data = {
          orders: order_list,
          total: order.remain,
          total_price: order.remain,
          data: order,
        }
        this.$store.commit('SET_ORDER', data)
        this.$router.push(`/openbill/detail/${order.order_id}`)
      })
    },
  },
}
</script>
<style scoped>
.bg-gradient {
  background: linear-gradient(0deg, #033654 0%, #0066a3 100%, #033654 100%);
}
</style>
